import React from "react"
import { chip, chipContent } from "./HybridChip.module.scss"

const PartnerChip = () => {
  return (
    <div className={chip}>
      <div className={chipContent}>Hybrid</div>
    </div>
  )
}

export default PartnerChip
