import { DateTime } from "luxon"

const dateString = (startDate: DateTime, endDate: DateTime) => {
  if (startDate.hasSame(endDate, "month")) {
    return startDate.toFormat("MMMM d") + "-" + endDate.toFormat("d, yyyy")
  } else {
    return (
      startDate.toFormat("MMMM d") + " to " + endDate.toFormat("MMMM d, yyyy")
    )
  }
}

export default dateString
