import React from "react"
import { chip, chipContent } from "./OnlineChip.module.scss"

const OnlineChip = () => {
  return (
    <div className={chip}>
      <div className={chipContent}>Online</div>
    </div>
  )
}

export default OnlineChip
