import React, { ComponentProps, ReactNode } from "react"
import Row from "./row"

interface BannerProps {
  children: ReactNode
}

const Banner: React.FC<BannerProps & ComponentProps<"div">> = ({
  children,
  ...props
}) => {
  return (
    <div id="banner-wrapper" {...props}>
      <div id="banner" className="box container">
        <Row>{children}</Row>
      </div>
    </div>
  )
}

export default Banner
