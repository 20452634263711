import React from "react"
import ReactGA from "react-ga"
import { MDXRenderer } from "gatsby-plugin-mdx"
import cx from "classnames"
import FeatureType from "../types/Feature"
import Container from "./container"
import Row from "./row"
import Column, { ColumnProps } from "./column"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Button from "./button"
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"

interface FeatureProps {
  features: FeatureType[]
}

const Features: React.FC<FeatureProps> = ({ features }) => {
  if (features.length > 3) throw new Error("Maximum allowed features is 3")
  const columnWidths = ((): Partial<ColumnProps> => {
    switch (features.length) {
      case 3: {
        return {
          large: 4,
          medium: 6,
          small: 12,
        }
      }
      case 2: {
        return {
          large: 6,
          medium: 6,
          small: 12,
        }
      }
      default: {
        return {
          large: 6,
          medium: 6,
          small: 12,
        }
      }
    }
  })()
  return (
    <div id="features-wrapper">
      <Container>
        <Row>
          {features.map((feature, i) => (
            <Column {...columnWidths} key={i}>
              <section className={cx("box", "feature")}>
                {feature.link.includes("://") ? (
                  <ReactGA.OutboundLink
                    eventLabel={"feature_image"}
                    to={feature.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cx("image", "featured")}
                  >
                    {feature.image && <Img fluid={feature.image} alt="" />}
                  </ReactGA.OutboundLink>
                ) : (
                  <Link
                    to={feature.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cx("image", "featured")}
                  >
                    {feature.image && <Img fluid={feature.image} alt="" />}
                  </Link>
                )}
                <div className="inner">
                  <header>
                    <h2>{feature.heading}</h2>
                    <p>{feature.subheading}</p>
                    <p>{feature.chips && feature.chips.map(chip => chip)}</p>
                  </header>
                  {!feature.isMdx && feature.body}
                  {feature.isMdx && (
                    <MDXRenderer>{feature.body as string}</MDXRenderer>
                  )}
                  <br />
                  {feature.buttonText && (
                    <Button
                      icon={faArrowCircleRight}
                      iconPos="right"
                      to={feature.link}
                      as={feature.link.includes("://") ? "a" : "link"}
                      eventLabel="feature_button"
                    >
                      {feature.buttonText}
                    </Button>
                  )}
                </div>
              </section>
            </Column>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Features
