import React from "react"
import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { RawLayout } from "../components/layouts"
import SEO from "../components/seo"
import Column from "../components/column"
import Banner from "../components/banner"
import Button from "../components/button"
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import Main from "../components/main"
import Row from "../components/row"
import Hackathons from "../components/hackathons"
import { Hackathon } from "../types/Hackathon"

interface LandingPageTemplateProps {
  data: {
    page: {
      body: string
      excerpt: string
      frontmatter: {
        title: string
        subtitle: string
        cta: Array<{
          text: string
          link: string
        }>
      }
    }
    hackathons: {
      edges: Array<{
        node: Hackathon & {
          featuredText: {
            json: object
          }
        }
      }>
    }
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
  pageContext: {
    today: string
  }
}

const LandingPageTemplate: React.FC<LandingPageTemplateProps> = ({
  data,
}) => {
  const { frontmatter, body } = data.page
  const { edges: hackathonEdges } = data.hackathons;
  const {
    siteMetadata: { title },
  } = data.site

  if (!!frontmatter.cta && frontmatter.cta.length > 2) {
    throw Error("Maximum allowed call to actions is 2")
  }

  return (
    <RawLayout>
      <SEO title={title} />
      <Banner>
        <Column large={7} medium={12}>
          <h2>{frontmatter.title}</h2>
          <p>{frontmatter.subtitle}</p>
        </Column>
        <Column large={5} medium={12}>
          <ul>
            {frontmatter.cta &&
              frontmatter.cta
                .filter(({ text }) => !!text)
                .map(({ link, text }, i) => (
                  <li key={i}>
                    <Button
                      large
                      icon={faArrowCircleRight}
                      iconPos="right"
                      to={link}
                      alt={!((i + 1) % 2)}
                    >
                      {text}
                    </Button>
                  </li>
                ))}
          </ul>
        </Column>
      </Banner>
      <Hackathons hackathons={hackathonEdges.map(hackathon => ({...hackathon.node}))} />
      <Main>
        <Row className="gtr-200">
          <Column>
            <MDXRenderer id="content">{body}</MDXRenderer>
          </Column>
        </Row>
      </Main>
    </RawLayout>
  )
}

export const LandingPageQuery = graphql`
  query LandingPageQuery($id: String!, $today: Date!) {
    page: mdx(id: { eq: $id }) {
      body
      excerpt(pruneLength: 140)
      frontmatter {
        title
        subtitle
        cta {
          text
          link
        }
      }
    }
    hackathons: allContentfulHackathon(
      filter: {
        partnered: { eq: true }
        endDate: { gte: $today }
        headerImage: { file: { url: { ne: null } } }
      }
      limit: 3
      sort: { order: ASC, fields: startDate }
    ) {
      edges {
        node {
          ...HackathonFields
          featuredText {
            json
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default LandingPageTemplate
