import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Document } from "@contentful/rich-text-types/dist/types"
import React from "react"
import { Hackathon } from "../types/Hackathon"
import Features from "./features"
import OnlineChip from "./OnlineChip"
import HybridChip from "./HybridChip"
import U18Chip from "./U18Chip"
import dateString from "../utils/hackathonDurationString"
import { DateTime } from "luxon"

interface HackathonsProps {
  hackathons: Array<Hackathon & {
    featuredText: {
      json: object
    }
  }>
}

const Hackathons: React.FC<HackathonsProps> = ({ hackathons }) => {
  return (
    <Features
      features={hackathons.map(hackathon => ({
        link: hackathon.url,
        image: hackathon.headerImage.fluid,
        heading: hackathon.name,
        subheading: `${dateString(
          DateTime.fromISO(hackathon.startDate),
          DateTime.fromISO(hackathon.endDate)
        )} (${hackathon.location})`,
        body: documentToReactComponents(
          hackathon.featuredText.json as Document
        ),
        isMdx: false,
        buttonText: "Sign Up",
        chips: [
          ...(hackathon.digital === "Yes"
            ? [<OnlineChip key={hackathon.name + "digital-yes"} />]
            : []),
          ...(hackathon.digital === "Hybrid"
            ? [<HybridChip key={hackathon.name + "hybrid"} />]
            : []),
          ...(hackathon.allAges
            ? [<U18Chip key={hackathon.name + "u18"} />]
            : []),
        ],
      }))}
    />
  )
}

export default Hackathons
