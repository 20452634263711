import React from "react"
import { chip, chipContent } from "./U18Chip.module.scss"

const U18Chip = () => {
  return (
    <div className={chip}>
      <div className={chipContent}>&lt;18</div>
    </div>
  )
}

export default U18Chip
